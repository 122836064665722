"use strict";
import * as contactForm from "../components/contactForm/script.js";
import * as contactTechSupportForm from "../components/contactTechSupportForm/script.js";
import * as roofRevForm from "../components/roofRevolutionForm/script.js";
import * as contactTabs from "../components/contactTabs/script.js";
import * as cpdForm from "../components/cpdForm/script.js";
import * as downloadModal from "../components/downloadModal/script.js";
import * as menu from "../components/menu/script.js";
import * as periodicElement from "../components/periodicElement/script.js";
import * as partnerMap from "../components/partnersMap/script.js";
import * as projectDetails from "../components/projectDetails/script.js";
import * as system from "../components/system/script.js";
import * as eventTracking from "./event-tracking";
import { psuedoSelect, smoothScroll, stickyPolyfill } from "./utilities.js";
import * as newsletterSignupForm from "../components/newsletterSignupForm/script.js";
import "../css/tailwind.css"
import "../css/app.scss";

document.addEventListener("DOMContentLoaded", function () {
    menu.init();
    periodicElement.init();
    system.init();
    psuedoSelect();
    smoothScroll();
    stickyPolyfill();
    contactTabs.init();
    contactForm.init();
    contactTechSupportForm.init();
    roofRevForm.init();
    eventTracking.init();
    downloadModal.init();
    projectDetails.init();
    cpdForm.init();
    newsletterSignupForm.init();
});
