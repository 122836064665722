"use strict";

import $ from "jquery";

const init = () => {
    $(document).keyup(function (event) {
        if (event.keyCode === 27 && $(".nav__search--open").length) {
            $(".nav__search").removeClass("nav__search--open");
        }
    });

    $(".js-search").click(function (event) {
        event.preventDefault();
        $(".nav__search__input").focus();
        $(".nav__search").toggleClass("nav__search--open");
    });
};

export { init };
