"use strict";

var $ = require("jquery");
window.jQuery = $;
require("@fancyapps/fancybox");

const init = () => {
    $("[data-partner-fancybox]").fancybox({
        baseClass: "fancybox--dark-close",
        iframe: {
            css: {
                width: "640px",
                height: "400px",
                overflow: "scroll"
            }
        }
    });
};

export { init };
