"use strict";

import $ from "jquery";

const init = () => {
    $(document).ready(function() {
        $(".contact-tabs__button").click(function() {
            $(".contact-tabs__button").removeClass(
                "contact-tabs__button--active"
            );
            $(this).addClass("contact-tabs__button--active");

            $(".content-reveal").removeClass("content-reveal--active");

            var reveal = $(this).data("reveal");
            $(".content-reveal[data-show=" + reveal + "]").addClass(
                "content-reveal--active"
            );
        });

        $(".contact-tabs__select").on("change", function() {
            $(".content-reveal").removeClass("content-reveal--active");

            var reveal = $(this).val();
            $(".content-reveal[data-show=" + reveal + "]").addClass(
                "content-reveal--active"
            );
        });
    });
};

export { init };
