"use strict";

var $ = require("jquery");
window.jQuery = $;

const init = () => {
    if (typeof Freeform != "undefined") {
        const newsletterForm = document.getElementById("newsletter-form");
        if (newsletterForm) {
            const freeform = Freeform.getInstance(newsletterForm);

            freeform.addOnSuccessfulAjaxSubmit((event, form, response) => {
                $("#newsletter-form-wrap").addClass("tw-hidden");
                $("#newsletter-thankyou-wrap").removeClass("tw-hidden");

                dataLayer.push({
                    event: "custom-event-trigger",
                    "event-category": "Form",
                    "event-action": "Submit",
                    "event-label": "Newsletter signup form",
                });
            });
        }
    }
};

export { init };
