"use strict";

import $ from "jquery";
import * as Stickyfill from "stickyfilljs";

const psuedoSelect = () => {
    // Toggle the dropdown on click
    $(".psuedo-select").click(function(event) {
        event.preventDefault();
        $(this)
            .find(".psuedo-select__options")
            .toggleClass("psuedo-select__options--open")
            .fadeToggle();
    });

    // Close on ESC if it's open
    $(document).keyup(function(e) {
        if (e.keyCode === 27 && $(".psuedo-select__options--open").length) {
            $(this)
                .find(".psuedo-select__options")
                .toggleClass("psuedo-select__options--open")
                .fadeToggle();
        }
    });

    // Close if you click outside of the dropdown...
    $("html").click(function() {
        if ($(".psuedo-select__options--open").length) {
            $(this)
                .find(".psuedo-select__options")
                .toggleClass("psuedo-select__options--open")
                .fadeToggle();
        }
    });

    // ...but don't close it if we're clicking within the dropdown
    $(".psuedo-select, .psuedo-select__options").click(function(event) {
        event.stopPropagation();
    });
};

const stickyPolyfill = () => {
    var elements = document.querySelectorAll('.stickyfill');
    Stickyfill.add(elements);
}

const smoothScroll = () => {
    var scroll = new SmoothScroll("a[data-scroll]", {
        offset: function(anchor, toggle) {
            // Integer or Function returning an integer. How far to offset the scrolling anchor location in pixels
            // This example is a function, but you could do something as simple as `offset: 25`

            // An example returning different values based on whether the clicked link was in the header nav or not
            return 150;
        }
    });
};

export { psuedoSelect, smoothScroll, stickyPolyfill };
