"use strict";

var $ = require("jquery");
window.jQuery = $;

const init = () => {
    if (typeof Freeform != "undefined") {
        const roofRevForm = document.getElementById("roof-rev-form");
        if (roofRevForm) {
            const freeform = Freeform.getInstance(roofRevForm);
            freeform.setOption(
                "successBannerMessage",
                "Thank you for signing up!"
            );
            freeform.addOnSuccessfulAjaxSubmit((event, form, response) => {
                $(".form__row").hide();
                $(".form__loading").hide();

                dataLayer.push({
                    event: "custom-event-trigger",
                    "event-category": "Form",
                    "event-action": "Submit",
                    "event-label": "Roof Revolution signup form",
                });
            });

            freeform.addOnFailedAjaxSubmit(function (event, form, response) {
                $(".form__loading").hide();
                var errors = response.errors;
                console.log(errors);
            });
        }
    }
};

export { init };
