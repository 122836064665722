"use strict";

import $ from "jquery";
import "slick-carousel";
import Cookies from "js-cookie";

const init = () => {
    $(document).ready(function () {
        $(".download-modal__slider").slick({
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: false,
            dots: false,
            autoplay: true,
            autoplaySpeed: 3000,
        });
    });

    $("[data-sign-up-modal]").click(function (e) {
        if (Cookies.get("CraftDownloadSignUp") == null) {
            var href = $(this).attr("href");
            $.fancybox.open({
                src: "/download-modal?v=2",
                type: "iframe",
                opts: {
                    caption: `Your download should have started automatically, but if not <a download href="${href}">click here</a>`,
                    iframe: {
                        css: {
                            width: "1140px",
                            overflow: "scroll",
                        },
                    },
                    baseClass: "download-modal__fancybox",
                },
            });
        }
    });

    const form = document.getElementById("download-form");
    if (form) {
        
        form.addEventListener('freeform-ajax-success', function(event) {
            Cookies.set("CraftDownloadSignUp", "true", { expires: 365 });

            $(".download-modal__success").css("display", "flex");

            dataLayer.push({
                event: "custom-event-trigger",
                "event-category": "Form",
                "event-action": "Submit",
                "event-label": "Download form",
            });
        });
    }
};

export { init };
