"use strict";

var $ = require("jquery");
window.jQuery = $;
require("@fancyapps/fancybox");

const init = () => {
    $(".periodic-modal a:not([data-target-self])").click(function (e) {
        e.preventDefault();
        window.open($(this).attr("href"));
    });

    $("[data-fancybox]").fancybox({
        iframe: {
            css: {
                width: "640px",
                height: "500px",
                overflow: "scroll",
            },
        },
    });
};

export { init };
