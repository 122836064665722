"use strict";

var $ = require("jquery");
window.jQuery = $;
require("is-in-viewport");

const init = () => {
    $(window).scroll(function() {
        $(".system__buildup").each(function() {
            if ($(this).is(":in-viewport")) {
                $(this)[0].play();
            } else {
                $(this)[0].pause();
            }
        });
    });
};

export { init };
