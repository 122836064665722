"use strict";

var $ = require("jquery");
window.jQuery = $;

const init = () => {
    if (typeof Freeform != "undefined") {
        const cpdForm = document.getElementById("cpd-form");
        if (cpdForm) {
            const freeform = Freeform.getInstance(cpdForm);
            freeform.setOption(
                "successBannerMessage",
                "Thanks. We will be in touch shortly"
            );
            freeform.addOnSuccessfulAjaxSubmit((event, form, response) => {
                dataLayer.push({
                    event: "custom-event-trigger",
                    "event-category": "Form",
                    "event-action": "Submit",
                    "event-label": "CPD Seminar form"
                });
            });
        }
    }
};

export { init };
