"use strict";

var $ = require("jquery");
window.jQuery = $;

const init = () => {
    if (typeof Freeform != "undefined") {
        const contactForm = document.getElementById("contact-tech-support-form");
        if (contactForm) {
            const freeform = Freeform.getInstance(contactForm);
            freeform.setOption(
                "successBannerMessage",
                "Thanks. We will be in touch shortly"
            );
            freeform.addOnSuccessfulAjaxSubmit((event, form, response) => {
                $(".contact-form__row").hide();

                dataLayer.push({
                    event: "custom-event-trigger",
                    "event-category": "Form",
                    "event-action": "Submit",
                    "event-label": "Contact Tech Support form"
                });
            });
        }
    }
};

export { init };
