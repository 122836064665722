var $ = require("jquery");
window.jQuery = $;

require("jquery.scrollto");

function initMap() {
    $(window).scroll(function() {
        $(".partner-info").removeClass("partner-info--selected");
    });

    $(".tooltip").on("mouseenter", function() {
        dataLayer.push({
            event: "custom-event-trigger",
            "event-category": "Tooltip",
            "event-action": "Hover",
            "event-label": window.location.href
        });
    });

    var data = JSON.parse(document.getElementById("data").innerHTML);

    var myOptions = {
        center: new google.maps.LatLng(45.4555729, 9.169236),
        zoom: 13,
        maxZoom: 14
    };

    var icons = {
        stockist: {
            url: "/assets/img/marker-s.svg",
            scaledSize: new google.maps.Size(27, 45)
        },
        installer: {
            url: "/assets/img/marker-i.svg",
            scaledSize: new google.maps.Size(27, 45)
        }
    };

    fetchPartners(
        data.type,
        data.near === "Near You" ? "London" : data.near,
        data.specialism,
        data.installer
    );

    if (data.near.toLowerCase() == "Near You".toLowerCase()) {
        const snackbar = $(".map-snackbar");
        const searchBar = $(".partners-search__search");

        if (navigator.geolocation) {
            snackbar.text("Trying to determine your location").fadeIn();

            navigator.geolocation.getCurrentPosition(
                location => {
                    snackbar
                        .addClass("map-snackbar--success")
                        .text("Map updated with your location");
                    searchBar.val("Near You");

                    dataLayer.push({
                        event: "custom-event-trigger",
                        "event-category": "GeoLocate",
                        "event-action": "Located user",
                        "event-label": window.location.href
                    });

                    setTimeout(function() {
                        snackbar.fadeOut();
                    }, 2000);

                    const near = `${location.coords.latitude},${location.coords.longitude}`;
                    fetchPartners(
                        data.type,
                        near,
                        data.specialism,
                        data.installer,
                        true
                    );
                },
                error => {
                    // see https://developer.mozilla.org/en-US/docs/Web/API/PositionError
                    // 1 - permission denied
                    // 2 - position unavailable
                    // 3 - timeout
                    if (error.code === 2) {
                        snackbar
                            .addClass("map-snackbar--error")
                            .text("Could not determine your location");
                    } else if (error.code === 3) {
                        snackbar
                            .addClass("map-snackbar--error")
                            .text("Location request timed out");
                    }
                    setTimeout(function() {
                        snackbar.fadeOut();
                    }, 2000);
                },
                {
                    timeout: 5000
                }
            );
        }
    }

    function fetchPartners(type, near, specialism, installer, geo = false) {
        $.when(
            $.getJSON(
                `/api/partners.json?type=${type}&near=${near}&geo=${+geo}&specialism=${specialism}&installer=${installer}`
            )
        ).then(function(partners) {
            if (!partners.length) {
                $("#map").hide();
                return;
            }

            var map = new google.maps.Map(
                document.getElementById("map"),
                myOptions
            );

            var bounds = new google.maps.LatLngBounds();

            partners.map(function(partner) {
                var item = new google.maps.LatLng(
                    partner.latLng[0],
                    partner.latLng[1]
                );
                var marker = new google.maps.Marker({
                    position: item,
                    map: map,
                    icon: icons[partner.type]
                });

                marker.addListener("click", function() {
                    var id = partner.id;
                    var el = $(".partner-info[data-partner-id='" + id + "']");

                    $(".partner-scrolling").scrollTo(el, 400, {
                        offset: {
                            top: 0
                        },
                        onAfter: function() {
                            requestAnimationFrame(function() {
                                $(el).addClass("partner-info--selected");
                            });
                        }
                    });

                    dataLayer.push({
                        event: "custom-event-trigger",
                        "event-category": "Clicks",
                        "event-action": "Marker",
                        "event-label": "Partner - " + id
                    });
                });

                bounds.extend(item);
            });

            map.fitBounds(bounds);
        });

        $.when(
            $.get(
                `/api/partnerItems?type=${type}&near=${near}&geo=${+geo}&specialism=${specialism}&installer=${installer}`
            )
        ).then(function(partnersHtml) {
            document.querySelector(".partner-items").innerHTML = partnersHtml;
        });
    }
}

window.initMap = initMap;
