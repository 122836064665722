"use strict";

import $ from "jquery";

const init = () => {
    $(document).ready(function() {
        $("[data-click-tracking]").click(function(e) {
            dataLayer.push({
                event: "custom-event-trigger",
                "event-category": $(this).data("click-tracking-category"),
                "event-action": $(this).data("click-tracking-action"),
                "event-label": $(this).data("click-tracking-label")
            });
        });
    });
};

export { init };
